.authed-layout {
  .header {
    height: 93px;
    background-color: #fff;
    border-bottom: 1px solid #cdcdcd;
  }
  .sider {
    background-color: #f8f9f9;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  }
  .content {
    min-height: 100vh;
  }
}
