.new-influencer {
  width: 800px;
  margin: 0 auto;
  .form-line {
    width: 800px;
    img {
      width: 200px;
      height: 200px;
      border-radius: 50%;
    }
  }
  .button-line {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
