.promotion-detail {
  width: 800px;
  margin: 10px auto;
  background-color: #fff;
  .code-form {
    width: 600px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto;
    .input-field {
      width: 400px;
    }
  }
}
