.unauthed-layout {
  .header {
    height: 93px;
    background-color: #fff;
    border-bottom: 1px solid #cdcdcd;
  }

  .content {
    height: 100vh;
  }
}
