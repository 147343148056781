.influencer {
  width: 800px;
  margin: 0 auto;
  .button-line {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .icon_img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
}
