.influencer-edit {
  width: 800px;
  margin: 0 auto;
  .button-line {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
}
