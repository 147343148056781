.sign-in {
  h1 {
    text-align: center;
  }
  .card {
    margin: 0 auto;
    width: 600px;
    form {
      display: block;
      margin: 0 auto;
      text-align: center;
      p {
        margin: 10px auto;
        text-align: left;
        width: 380px;
      }
      .input-line {
        margin: 10px auto;
        width: 400px;
        input {
          width: 380px;
          height: 45px;
          font-size: 18px;
        }
      }
      .button-line {
        margin: 20px auto;
        text-align: center;
      }
    }
  }
}
