.promotions {
  width: 800px;
  margin: 0 auto;
  .button-line {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0 auto;
    width: 800px;
  }
}
