.course-detail {
  width: 800px;
  margin: 0 auto;
  .button-line {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .duplicate-caution {
    background-color: #ffa6a6;
    border: 1px solid red;
    color: #fff;
    text-align: center;
    padding: 10px;
  }
}
